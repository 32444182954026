import React, { FC, useContext, useRef } from "react";
import { graphql, Link } from "gatsby";
import { AudiencePageQuery, BlockFragment } from "../../graphql-types";
import { WavySection } from "../components/wavy-section";
import {
  colors,
  ySpaceRem,
  fontStacks,
  targetBaseFontSizePx,
  xSpaceRem,
} from "../global-style";
import { LayoutContext } from "../components/layout";
import { Center } from "../components/center";
import { css } from "styled-components";
import { Blocks } from "../components/blocks";
import { Footer } from "../components/footer";
import { StoryPreview } from "../components/story-preview";
import { Grid } from "../components/grid";
import { SEO } from "../components/seo";

type Props = {
  data: AudiencePageQuery;
};

const Audience: FC<Props> = props => {
  const { remInPx, waveClearance } = useContext(LayoutContext);
  const pageTitleRef = useRef<HTMLHeadingElement>(null);

  return (
    <>
      <SEO {...props.data.wp.audience?.seo} />

      <WavySection
        backgroundColor={colors.mediumBlue}
        style={{
          color: colors.white,
          paddingTop: `calc(${waveClearance} + ${2 * ySpaceRem}rem)`,
          paddingBottom: `${ySpaceRem}rem`,
          transition: "padding 0.25s ease",
        }}
      >
        <Center>
          <div
            style={{
              ...(pageTitleRef.current &&
                (() => {
                  const paddingYRem =
                    ySpaceRem / 2 -
                    ((pageTitleRef.current.getBoundingClientRect().height /
                      remInPx) %
                      (ySpaceRem / 2));

                  return {
                    paddingTop: `${0.5 * paddingYRem}rem`,
                    paddingBottom: `${0.5 * paddingYRem}rem`,
                  };
                })()),
              transition: "padding 0.25s ease",
            }}
          >
            <h1
              ref={pageTitleRef}
              style={{
                fontFamily: fontStacks.cubano,
              }}
              css={(() => {
                const minFontSizeRem = 2;
                const maxFontSizeRem = 2.8888;
                const vwRangeStartPx = 500;

                return css`
                  font-size: ${(minFontSizeRem *
                    (remInPx || targetBaseFontSizePx)) /
                    (vwRangeStartPx / 100)}vw;
                  line-height: 1;

                  @media (max-width: ${vwRangeStartPx}px) {
                    font-size: ${minFontSizeRem}rem;
                  }

                  @media (min-width: ${vwRangeStartPx *
                      (maxFontSizeRem / minFontSizeRem)}px) {
                    font-size: ${maxFontSizeRem}rem;
                  }
                `;
              })()}
            >
              {props.data.wp.audience?.title}
            </h1>
          </div>
        </Center>
      </WavySection>

      <div
        style={{
          paddingTop: `${1.5 * ySpaceRem}rem`,
          paddingBottom: `calc(${1.5 * ySpaceRem}rem + ${waveClearance})`,
        }}
      >
        <Center>
          <div style={{ maxWidth: `${32 * xSpaceRem}rem` }}>
            <div style={{ marginBottom: `${1.5 * ySpaceRem}rem` }}>
              <Blocks
                blocks={props.data.wp.audience?.blocks as BlockFragment[]}
              />
            </div>

            <div style={{ marginBottom: `${0.5 * ySpaceRem}rem` }}>
              <h2
                style={{
                  fontFamily: fontStacks.cubano,
                  marginBottom: `${ySpaceRem}rem`,
                  borderBottom: `1px solid ${colors.black}`,
                  paddingBottom: `${0.5 * ySpaceRem}rem`,
                }}
              >
                Klimaatverhalen
              </h2>

              <Grid style={{ marginBottom: `${ySpaceRem}rem` }}>
                {props.data.wp.audience?.audienceCustomFields?.audiencesStories
                  ?.slice(0, 2)
                  .map(
                    (story, i) =>
                      story && <StoryPreview key={i} story={story} />
                  )}
              </Grid>

              <Link
                to="/verhalen/"
                style={{
                  borderRadius: "1.5rem",
                  border: "none",
                  backgroundColor: colors.orange,
                  fontSize: "1rem",
                  fontWeight: 700,
                  fontFamily: fontStacks.univiaPro,
                  color: colors.white,
                  paddingTop: `${0.25 * ySpaceRem}rem`,
                  paddingBottom: `${0.25 * ySpaceRem}rem`,
                  paddingLeft: `${0.5 * ySpaceRem}rem`,
                  paddingRight: `${0.5 * ySpaceRem}rem`,
                  marginBottom: `${0.5 * ySpaceRem}rem`,
                }}
              >
                Bekijk meer verhalen &rarr;
              </Link>
            </div>
          </div>
        </Center>
      </div>

      <Footer backgroundColor={colors.lightestGrey} />
    </>
  );
};

export default Audience;

export const pageQuery = graphql`
  query AudiencePage($id: ID!) {
    wp {
      audience(id: $id, idType: ID) {
        id
        blocks {
          ...Block
        }
        title
        seo {
          ...SEO
        }
        audienceCustomFields {
          audiencesStories {
            ... on Wp_Story {
              ...Story
            }
          }
        }
      }
    }
  }
`;
